.element {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 7924px;
  display: flex;
  position: relative;
}

.element .intro {
  background-color: #e5f17e;
  width: 1920px;
  height: 1200px;
  position: relative;
}

body {
  overflow-x: hidden;
}

.element .group {
  width: 1081px;
  height: 1012px;
  position: absolute;
  top: 188px;
  left: 839px;
}

.element .overlap-group {
  background-image: url("scene-1-2x-2.cb3e9d42.png");
  background-size: 100% 100%;
  width: 1165px;
  height: 1120px;
  position: relative;
  top: -54px;
  left: -54px;
}

.element .scene {
  width: 1056px;
  height: 1012px;
  position: absolute;
  top: 54px;
  left: 78px;
}

.element .media-entertainment {
  color: #202020;
  letter-spacing: -.39px;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 768px;
}

.element .text-wrapper {
  color: #202020;
  letter-spacing: -.39px;
  text-align: right;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 1402px;
}

.element .div {
  color: #202020;
  letter-spacing: -2.39px;
  width: 655px;
  height: 258px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 80px;
  font-weight: 700;
  line-height: 86.4px;
  position: absolute;
  top: 252px;
  left: 120px;
}

.element .we-ve-got-issues-is {
  color: #202020;
  letter-spacing: -.39px;
  width: 655px;
  height: 160px;
  font-family: Neue Haas Grotesk Text Pro-Medium, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  position: absolute;
  top: 542px;
  left: 120px;
}

.element .span {
  font-weight: 500;
}

.element .text-wrapper-2 {
  font-family: Neue Haas Grotesk Text Pro-Regular, Helvetica;
}

.element .group-2 {
  width: 461px;
  height: 112px;
  position: absolute;
  top: 783px;
  left: 122px;
}

.element .UIX {
  width: 160px;
  height: 56px;
  position: absolute;
  top: 56px;
  left: 0;
}

.element .overlap {
  background-color: #2934d0;
  border-radius: 28px;
  width: 158px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-3 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  width: 66px;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 64px;
}

.element .overlap-group-wrapper {
  background-color: #f8f8f8;
  border-radius: 23.06px;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 5px;
  left: 6px;
}

.element .overlap-group-2 {
  width: 21px;
  height: 21px;
  position: relative;
  top: 12px;
  left: 12px;
}

.element .line {
  width: 5px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 8px;
}

.element .img {
  width: 21px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.element .WD {
  width: 287px;
  height: 56px;
  position: absolute;
  top: 56px;
  left: 174px;
}

.element .overlap-2 {
  background-color: #fb62f6;
  border-radius: 28px;
  width: 285px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-4 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 64px;
}

.element .text-wrapper-5 {
  color: #202020;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .logo {
  width: 169px;
  height: 118px;
  position: absolute;
  top: 29px;
  left: 120px;
}

.element .screens {
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.element .laptop {
  width: 936px;
  height: 1000px;
  position: relative;
}

.element .mask-group-wrapper {
  background-color: #ff4242;
  width: 938px;
  height: 1007px;
  position: relative;
}

.element .mask-group {
  width: 938px;
  height: 1000px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .mobile {
  background-image: url("mask-group.afbf9c06.png");
  background-size: 100% 100%;
  width: 984px;
  height: 1000px;
  position: relative;
}

.element .dribbble-shot-HD {
  width: 1920px;
  height: 2574px;
  position: relative;
}

.element .frame {
  width: 1938.33px;
  height: 3161px;
  margin-bottom: -11px;
  margin-right: -18.33px;
  position: relative;
}

/*# sourceMappingURL=index.d16ea005.css.map */
