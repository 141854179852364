.element {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 7924px;
  /* overflow-y: hidden; */
  position: relative;
  width: 100%;
}
body{
  /* overflow-x: hidden; */

}
.element .intro {
  background-color: #e5f17e;
  height: 1200px;
  position: relative;
  width: 1920px;
}
body{
  overflow-x: hidden;
}
.element .group {
  height: 1012px;
  left: 839px;
  position: absolute;
  top: 188px;
  width: 1081px;
}

.element .overlap-group {
  background-image: url(../../../static/img/scene-1-2x-2.png);
  background-size: 100% 100%;
  height: 1120px;
  left: -54px;
  position: relative;
  top: -54px;
  width: 1165px;
}

.element .scene {
  height: 1012px;
  left: 78px;
  position: absolute;
  top: 54px;
  width: 1056px;
}

.element .media-entertainment {
  color: #202020;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 34px;
  left: 768px;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.element .text-wrapper {
  color: #202020;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 34px;
  left: 1402px;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 70px;
}

.element .div {
  color: #202020;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 80px;
  font-weight: 700;
  height: 258px;
  left: 120px;
  letter-spacing: -2.39px;
  line-height: 86.4px;
  position: absolute;
  top: 252px;
  width: 655px;
}

.element .we-ve-got-issues-is {
  color: #202020;
  font-family: "Neue Haas Grotesk Text Pro-Medium", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 160px;
  left: 120px;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 542px;
  width: 655px;
}

.element .span {
  font-weight: 500;
}

.element .text-wrapper-2 {
  font-family: "Neue Haas Grotesk Text Pro-Regular", Helvetica;
}

.element .group-2 {
  height: 112px;
  left: 122px;
  position: absolute;
  top: 783px;
  width: 461px;
}

.element .UIX {
  height: 56px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 160px;
}

.element .overlap {
  background-color: #2934d0;
  border-radius: 28px;
  height: 56px;
  position: relative;
  width: 158px;
}

.element .text-wrapper-3 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  height: 33px;
  left: 64px;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
  width: 66px;
}

.element .overlap-group-wrapper {
  background-color: #f8f8f8;
  border-radius: 23.06px;
  height: 46px;
  left: 6px;
  position: absolute;
  top: 5px;
  width: 46px;
}

.element .overlap-group-2 {
  height: 21px;
  left: 12px;
  position: relative;
  top: 12px;
  width: 21px;
}

.element .line {
  height: 21px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 5px;
}

.element .img {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 21px;
}

.element .WD {
  height: 56px;
  left: 174px;
  position: absolute;
  top: 56px;
  width: 287px;
}

.element .overlap-2 {
  background-color: #fb62f6;
  border-radius: 28px;
  height: 56px;
  position: relative;
  width: 285px;
}

.element .text-wrapper-4 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  height: 33px;
  left: 64px;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.element .text-wrapper-5 {
  color: #202020;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 40px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .logo {
  height: 118px;
  left: 120px;
  position: absolute;
  top: 29px;
  width: 169px;
}

.element .screens {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.element .laptop {
  height: 1000px;
  position: relative;
  width: 936px;
}

.element .mask-group-wrapper {
  background-color: #ff4242;
  height: 1007px;
  position: relative;
  width: 938px;
}

.element .mask-group {
  height: 1000px;
  left: 0;
  position: absolute;
  top: 0;
  width: 938px;
}

.element .mobile {
  background-image: url(../../../static/img/mask-group.png);
  background-size: 100% 100%;
  height: 1000px;
  position: relative;
  width: 984px;
}

.element .dribbble-shot-HD {
  height: 2574px;
  position: relative;
  width: 1920px;
}

.element .frame {
  height: 3161px;
  margin-bottom: -11px;
  margin-right: -18.33px;
  position: relative;
  width: 1938.33px;
}
